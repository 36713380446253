import React from "react";
import {ImageDownload} from '../../components'
import IphoneImage2 from "../../assets/ios-images/iPhone_1.c5309f1009d0aeefa934.png"
import IphoneImage4 from "../../assets/ios-images/iPhone_2.3d089edfcf1bc89a0392.jpg"

export const IphoneImages = () => {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={IphoneImage2} filename="iphone-12-1"/>
                    <ImageDownload imageUrl={IphoneImage4} filename="iphone-12-2"/>
                </div>
            </div>
        </>
    )
}