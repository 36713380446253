import React from "react";
import {ImageDownload} from '../../components'
import AndroidImage2 from "../../assets/android-images/android_1.560d813a5c3172a1d845.png"
import AndroidImage4 from "../../assets/android-images/android_2.33023557ff8bc20953fd.jpg"

export function AndroidImages() {
    return (
        <>
            <div className="images">
                <div className="images-first-row">
                    <ImageDownload imageUrl={AndroidImage2} filename="samsung-s21-1"/>
                    <ImageDownload imageUrl={AndroidImage4} filename="samsung-s21-2"/>
                </div>
            </div>
        </>
    )
}