import {Routes, Route} from "react-router-dom";
import {ErrorPage, Home, IphoneImages, AndroidImages} from "./pages";

function App() {
    return (
        // <div className="pages">
            <Routes>
                <Route path="*" element={<ErrorPage/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/images">
                    <Route path="/images/ios" element={<IphoneImages/>}/>
                    <Route path="/images/android" element={<AndroidImages/>}/>
                </Route>
            </Routes>
        // </div>
    );
}

export default App;
