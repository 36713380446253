import React from "react";
import AndroidImage from "../../assets/android.png"
import IosImage from "../../assets/ios.png"
import {Link} from "react-router-dom";
import './home.style.scss'

export function Home() {
    return (
        <div className="home">
            <div className="home-section">
                <Link to="/images/ios">
                    <img src={IosImage}/>
                </Link>
                <Link to="/images/android">
                    <img src={AndroidImage}/>
                </Link>
            </div>
        </div>
    )
}